<template>
  <div>
    <AutoPopup />
    <div class="hero-container-small"></div>
    <div class="hero-container">
      <b-container class="a-hero-text-container d-flex">
        <div class="a-hero-text-container-inner w-100">
          <h1 class="mb-0 mt-sm-5 mt-xs-3 mt-0">
            <span>{{ $t("prepare") }}&nbsp;</span>
            <span class="w-color-primary z-fancy-underline">
              {{ $t("for-life") }}
              <svgFancyUnderline />
            </span>
          </h1>
          <p class="mb-0 mt-sm-5 mt-3">
            {{ $t("platform-full-of-courses") }}
            <br />
            {{ $t("for-schools-teachers-students") }}
          </p>
          <div class="d-flex flex-column align-items-start">
            <b-button
              variant="primary"
              class="a-poppins-btn -wider mt-3"
              v-bind="primaryButtonLink"
              >{{ primaryButtonText }}</b-button
            >
            <b-button
              variant="secondary"
              class="a-poppins-btn -wider mt-3"
              :to="secondaryButtonLink"
              >{{ secondaryButtonText }}</b-button
            >
          </div>
        </div>
      </b-container>
    </div>

    <TopCourses
      v-if="featuredCourses"
      :courses="featuredCourses"
      :category="topCoursesCategory"
    />
    <LatestCourses v-if="latestCourses" :courses="latestCourses" />

    <Benefits />
    <Testimonials />
    <Stats />
    <AboutNapisaliONasSection />
    <!-- <a-competition-modal ref="competition-modal"/> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { fetchHomePageData } from "/utils/shared";
import AutoPopup from "/components/AutoPopup.vue";
import * as Sentry from "@sentry/vue";

export default {
  components: {
    AutoPopup,
    svgFancyUnderline: () => import("/assets/icons/fancyUnderline.svg?inline"),
    Stats: () => import("/components/Stats.vue"),
    Testimonials: () => import("/components/Testimonials"),
    Benefits: () => import("/components/Benefits"),
    TopCourses: () => import("/components/TopCourses"),
    LatestCourses: () => import("/components/LatestCourses"),
    AboutNapisaliONasSection: () =>
      import("/components/About/AboutNapisaliONasSection.vue"),
  },

  data() {
    return {
      featuredCourses: null,
      latestCourses: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn", "user"]),
    isAdmin() {
      return this.user && this.user.type === "teacher";
    },
    primaryButtonLink() {
      if (this.isLoggedIn) {
        return this.isAdmin ? { to: "/kurzy" } : { href: "https://zmudrig.sk" };
      }

      return { to: "/registracia" };
    },
    primaryButtonText() {
      if (this.isLoggedIn) {
        return this.isAdmin
          ? this.$t("our-courses")
          : this.$t("media-web-for-youth");
      }

      return this.$t("register-for-free");
    },
    secondaryButtonLink() {
      if (this.isLoggedIn && !this.isAdmin) {
        return "/kurzy";
      }

      return "/pre-ucitelov";
    },
    secondaryButtonText() {
      if (this.isLoggedIn && !this.isAdmin) {
        return this.$t("our-courses");
      }

      return this.$t("for-teachers");
    },
    topCoursesCategory() {
      return this.isAdmin ? "teacher" : "student";
    },
  },

  async mounted() {
    try {
      const { featuredCourses, latestCourses } = await fetchHomePageData(
        "student"
      );

      this.featuredCourses = featuredCourses;
      this.latestCourses = latestCourses;
    } catch (error) {
      Sentry.captureException(error);
      this.featuredCourses = { error: this.$t("unable-to-load-courses") };
    }
  },
};
</script>

<style lang="scss" scoped>
.hero-container-small {
  background-image: url("/backgroundStudents.png");
  background-position: -270px top;

  @media only screen and (min-width: 325px) {
    background-position: right top;
  }
  @media only screen and (min-width: 445px) {
    height: 25rem;
    background-size: 46.875rem;
  }

  @media only screen and (min-width: 600px) {
    height: 28.125rem;
    background-size: 53.125rem;
  }
}

.hero-container {
  @media only screen and (min-width: 768px) {
    background-image: url("/backgroundStudents.png");
  }

  h1 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media only screen and (min-width: 768px) {
      max-width: 12rem;
    }
  }
}
</style>
